/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 3.1.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";

//Components
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/ribbons";
@import "custom/components/print";
@import "custom/components/preloader";

//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/logout";
@import "custom/pages/faq";
@import "custom/pages/maintenance";
@import "custom/pages/timeline";
@import "custom/pages/email";
@import "custom/pages/sitemap";
@import "custom/pages/search-results";
@import "custom/pages/pricing";
@import "custom/pages/gallery";
@import "custom/pages/coming-soon";
@import "custom/pages/profile";
@import "custom/pages/taskboard";
@import "custom/pages/ecommerce";

// Vendors
@import "vendor/metisMenu";


// Plugins
@import "custom/plugins/waves";
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/calendar";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/slimscroll";
@import "custom/plugins/toaster";
@import "custom/plugins/sweetalert";
@import "custom/plugins/custom-select";
@import "custom/plugins/tippy";
@import "custom/plugins/nestable-list";
@import "custom/plugins/hopscotch";
@import "custom/plugins/flot";
@import "custom/plugins/morris";
@import "custom/plugins/chartjs";
@import "custom/plugins/chartist";
@import "custom/plugins/c3";
@import "custom/plugins/ricksaw";
@import "custom/plugins/responsive-table";
@import "custom/plugins/footables";
@import "custom/plugins/bootstrap-tables";
@import "custom/plugins/tablesaw";
@import "custom/plugins/jsgrid";
@import "custom/plugins/multiple-select";
@import "custom/plugins/autocomplete";
@import "custom/plugins/bootstrap-select";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/flatpickr";
@import "custom/plugins/clockpicker";
@import "custom/plugins/summernote";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/dropzone";
@import "custom/plugins/dropify";
@import "custom/plugins/x-editable";
@import "custom/plugins/cropper";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";
@import "custom/plugins/mapeal-maps";


.home{
    background-color: #F8F8FB;
    padding: 0px;
    font-family: Roboto;
}

.imageLogoin{
    width: 100%;
    position: absolute;
    top: -26px;
}
.PCard{
    border-radius: 18px;
    box-shadow: 1px 3px 18px #e3e3e3;
    background: #fff;
}
.inputLogin{
    background: #FAFAFB 0% 0% no-repeat padding-box;
    border: 1px solid #F1F1F5;
    border-radius: 11px;
    height: 40px;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #5100dd;
    border-color: #34038b;
    background-color: #5d00ff;
}

@media only screen and (max-width: 600px) {
    .backgroundLogin {
      display: none;
    }
}
.navbar-custom{
    height: 79px;
    padding-top: 4px;
    background: #ffffff;
}
.navbar-custom .topnav-menu .nav-link {
    padding: 0 15px;
    color: #4E7D96;
    min-width: 32px;
    display: block;
    line-height: 70px;
    text-align: center;
    max-height: 70px;
}


.content-page {
    margin-left: 240px;
    overflow: hidden;
    padding: 0 15px 5px 15px;
    min-height: 80vh;
    margin-top: 100px;
}
.navbar-custom {
    box-shadow: none;
}
.titleheader{
    font-size: 28px;
    color: #6868ab!important;
    font-family: Poppins;
    font-weight: 500;
}

.btn-danger {
    color: #fff;
    background-color: #ff1036;
    border-color: #ff1036;
    border-radius: 7px;
    font-size: 15px;
    padding: 6px 15px;
}
.HeaderstatusContent{
    width: 100%;
    overflow: auto;
    display: -webkit-box;
    margin-top: 15px;
    padding-bottom: 20px;
}
.HeaderstatusContent .boxStatus{
    width: 127px;
    height: 151px;
    border-radius: 10px;
    background: #e9e9e9;
    text-align: center;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    margin-left: 12px;
    padding: 18px;
    -webkit-box-shadow: 1px 1px 8px #4e7d960f;
    box-shadow: 1px 1px 8px #4e7d960f;
    position: relative;
}
.HeaderstatusContent .backGroundWhite{
    background: #fff;
}
.HeaderstatusContent .backGroundWhite svg{
    position: absolute;
    left: 1px;
    top: 0px;
}
.HeaderstatusContent .boxStatus a{
    position: absolute;
    left: 0;
    bottom: 21px;
    width: 100%;
    padding: 14px;
}

.tabelStatus{
    height: 50px;
    background: #fff;
    border: none;
    border-radius: 9px 9px 0 0;
}
.tabelStatus tr th {
    height: 50px;
    border: none;
    padding: 0 48px;
}
.table thead th {
    vertical-align: inherit;
    border-bottom: 2px solid #dee2e6;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-bottom: 0 !important;
    border-radius: 0;
    margin-top: 4px !important;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
    padding-right: 30px;
    height: 60px;
    border: none;
}

.table td, .table th {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
}
.table img{
    width: 37px;
    height: 37px;
    border-radius: 5px;
    margin-right: 7px;
    border: 1px dashed #7d57c2;
}

.table .Approval{
    background: #e087b71c;
    padding: 6px 35px;
    border-radius: 5px;
    color: #ff61b6;
}
.table .Draft{
    background: #e9e9e9;
    padding: 6px 35px;
    border-radius: 5px;
    color: #898787;
}
.table .TreatmentSetup{
    background: #ebc27142;
    padding: 6px 35px;
    border-radius: 5px;
    color: #f1a206;
}
.table .Fabrication{
    background: #6869ab66;
    padding: 6px 35px;
    border-radius: 5px;
    color: #5f61ad;
}
.table tbody tr:hover{
    background: #efefef;
}
.table .Revision{
    background: #85a1ac17;
    padding: 6px 35px;
    border-radius: 5px;
    color: #85A1AC;
}
.table .Delivered{
    background: #8ad56f18;
    padding: 6px 35px;
    border-radius: 5px;
    color: #8AD56F;
}
.table .Processing{
    background: #ea435c21;
    padding: 6px 35px;
    border-radius: 5px;
    color: #EA435D;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 1.4em!important;
    display: block;
    opacity: 0.3;
}
.dataTables_scrollHeadInner{
    margin-bottom: 2px !important;
}

.table td, .table th {
    padding: 0.5rem;
    vertical-align: inherit;
    border-top: 1px solid #dee2e6;
    text-align: left;
}
.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #7e57c2;
    border-color: #7e57c2;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-start !important;
    margin-top: 16px !important;
}
.pagination-rounded .page-link {
    border-radius: 6px!important;
    margin: 0 3px;
    border: none;
}


div.dataTables_wrapper div.dataTables_info {
    padding-top: 1.85em !important;
    white-space: nowrap;
}

.imageCoverCases{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .4;
    z-index: -1;
}

.tabNavTitleL{
    width: 100%;
    height: 10px;
    background: #fff;
    border-radius: 20px 0px 0px 20px;
    margin-bottom: 5px;
}
.tabNavTitleR{
    width: 100%;
    height: 10px;
    background: #fff;
    border-radius: 0px 7px 7px 0px;
    margin-bottom: 5px;
}
.tabNavTitle{
    width: 100%;
    height: 10px;
    background: #fff;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 5px;
}
.boxTabNavTitle{
    width: 135px;
    display: inline-block;
    cursor: pointer;
}
.boxTabNavTitle a{
    font-family: 'Poppins';
    font-weight: 100;
    font-size: 11px;
}
.navActive a{
    color: #6868AC !important;
    font-weight: 600 !important;
}
.navActive div{
    background: #6868AC;
}


.cardheaderCases{
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    color: #6868ab;
    background: #fff;
    margin-bottom: 1px;
    border-radius: 10px 10px 0px 0px;
}
.cardBodyCases{
    background: #fff;
    border-radius: 0px 0px 10px 10px;
}

.btnContinue{
    background: #6868ab;
    color: #fff !important;
    border: 1px solid #6868ab;
    border-radius: 4px;
    padding: 8px 84px;
    font-size: 17px;
    font-family: 'Poppins';
    cursor: pointer;
}
.btnContinue:hover{
    background: #5858ad;
}
.btnContinue:active{
    background: #6161d1;
}
.boxTabsn{
    display: none;
}
.active{
    display: block;
}
.imagePatient{
    width: 100%;
    border-radius: 6px;
    border: 0.4px solid #f1f1f1;
    cursor: pointer;
    height: 136px;
}
.imgAvatar{
    cursor: pointer;
    width: 131px;
    box-shadow: 1px 1px 8px #f1f1f1;
    border-radius: 11px;
}
.itemsOrder{
    background: #fff;
    margin-top: 1px;
    min-height: 52px;
}
.inputCasesorder{
    background: #ffffff 0 0 no-repeat padding-box;
    border-radius: 6px;
    width: fit-content;
    border: none;
    text-align: center;
}

.titleFormCreateOrder{
    font-size: 15px;
    margin: 0;
}
.titleGROUPCREATEORDER{
    justify-content: center;
    align-items: center;
}

.inputItemsCreateOrder{
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 0;
    border-bottom: 1px dashed;
    width: 100%;
    text-align: left;
}
.inputChekbox{
    border: none;
    border-bottom: 1px dashed;
    outline: none;
    width: 89px;
    text-align: center;
}
.higtAuto{
    height: auto !important;
}
.IPRDISGIN svg{
    cursor: pointer;
}
.IPRDISGIN svg:hover{
    -webkit-filter: drop-shadow(1px 1px 5px #6868aa);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.divColome{
    text-align: center;
    padding: 1px;
}
.divColome div{
    width: 0;
    border: 1px dashed;
    height: 36px;
    margin-left: 6.3px;
    margin-right: 7px;
    color: red;
    opacity: 0;
}
.divColome a{
    font-size: 11px;
    font-weight: 500;
    color: #6868aa !important;
    opacity: 0;
}
.boxIPROption{
    width: 100%;
    height: 100%;
    background: #ffffffb3;
    position: absolute;
    top: 0;
    left: 0;
}
.cardIPRBOX{
    z-index: 99999999;
    position: relative;
    box-shadow: 4px 4px 10px #dadaff;
    margin-top: 12px;
}
.activeIPR{
    -webkit-filter: drop-shadow(1px 1px 5px #6868aa);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.boxSVGIPR{
    display: inline-flex;
    width: 100%;
    text-align: center;
    align-items: center;
    text-align: center;
    margin-bottom: 8px;
    border-bottom: 1px solid #f3efef;
    padding-bottom: 4px;
}
.showSVGIPR{
    width: 16px;
}
.titleSGIPR{
    width: 100%;
    font-size: 21px;
}

.btn-outline-darkK:hover {
    color: #fff;
    background-color: #d5d5d5;
    border-color: #7c8087;
}
.dropify-wrapper{
    border: none!important;
    height: 100px!important;
    width: 100% !important;
    padding: 0 !important;
}
.dropify-font-upload:before, .dropify-wrapper .dropify-message span.file-icon:before {
    color: #6868aa;
}

.boxChat{
    width: 100%;
}
.boxChat .boxInput{
    width: 100%;
    display: inline-flex;
    margin-top: 20px;
    align-items: flex-end;
    .inputMessage{
        width: 100%;
        margin-right: 10px;
        border: 1px solid #cbcbcb;
        border-radius: 32px;
        height: max-content;
        padding: 10px 16px;
        max-height: 150px;
        textarea{
            display: block;
            width: 100%;
            border: 0;
            outline: 0;
            resize: none;
            overflow-y: hidden;
            background: rgba(255,255,255,0);
            font-size: 14px;
            color: #343434;
            height: 25px;
        }
    }
    .btnMessage{
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
    }
    .btnMessage:hover{
        -webkit-filter: drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5));
    }
    .btnMessage:active{
        -webkit-filter: drop-shadow( 1px 5px 8px rgba(0, 0, 0, .5));
    }


}
.boxChat .boxItems{
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding: 0px 15px;
}

.boxChat .boxItems .itemChat{
    display: inline-flex;
    width: 100%;
    margin-top: 16px;
    direction: rtl;
}
.boxChat .boxItems .itemChat .imageAvatar{
    margin-right: 7px;
    img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: none;
    }
} 
.boxChat .boxItems .itemChat .Message{
    background: #f2f5f7;
    color: #000;
    padding: 11px 18px;
    border-radius: 11px;
    font-size: 11px;
    max-width: 50%;
}
.boxChat .boxItems .dateMessage{
    text-align: right;
    color: #6868ab;
    font-weight: 500;
}
.boxChat .boxItems  .other{
    direction: ltr !important;
}
.boxChat .boxItems .other .imageAvatar img{
    display: block !important;
}
.boxChat .boxItems .other .dateMessage{
    text-align: left;
}

.tabPanels{
    margin-top: 28px;
}

.errorInput{
    border: 1px dashed red;
}
.sucssInput{
    border: 1px dashed rgb(21, 214, 4);
}

.imageUserTopBar{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
}
.notifiError{
    width: 100%;
    background: #a5111121;
    color: #767474;
    padding: 3px 24px;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
    font-family: initial;
}
.ladda-button[data-style=expand-right][data-loading] {
    padding-right: 56px;
    background: #000;
    color: #fff !important;
}

.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .notFondPage .four_zero_four_bg h1{
 font-size:80px;
 }
 
 .notFondPage .four_zero_four_bg h3{
			 font-size:80px;
 }
			 
.notFondPage .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}
.notFondPage .contant_box_404{ 
    margin-top:-50px;
}

.createOrderImage .dropify-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
    background-color: #ffffff00;
    border: 1px dashed #dbdbdb !important;
}
.dropify-font-upload:before, .dropify-wrapper .dropify-message span.file-icon:before {
    content: '' !important;
}
.dropify-wrapper .dropify-message p {
    margin: 5px 0 0;
    display: none;
}
.createOrderImage .divProsess{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000007d;
    border-radius: 10px;
}
.createOrderImage .textProsess{
    position: absolute;
    top: 39%;
    left: 36%;
    color: #fff !important;
    font-size: 19px;
    font-weight: 600;
}
.createOrderImage .loaderProsess{
    position: absolute;
    top: 14%;
    left: 16%;
}
.dragdrop{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    cursor: pointer;
}
.dragdropHover{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    background-size: 30px 30px;
    background-image: -webkit-linear-gradient(
135deg
,#F6F6F6 25%,transparent 25%,transparent 50%,#F6F6F6 50%,#F6F6F6 75%,transparent 75%,transparent);
    background-image: linear-gradient(
-45deg
,#F6F6F6 25%,transparent 25%,transparent 50%,#F6F6F6 50%,#F6F6F6 75%,transparent 75%,transparent);
    -webkit-animation: stripes 2s linear infinite;
    animation: stripes 2s linear infinite;
}

.dragdrop .ErrorFile{
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    align-items: center;
    display: inline-flex;
    width: 100%;
    height: 100%;
    background: #d90b0b9c;
    color: #fff;
    text-shadow: 1px 1px 8px #000;
    border-radius: 11px;
}

.dragdrop .imageIcon{
    display: block;
    width: 100%;
    height: 100%;
    font-size: 84px;
    border: 1px dashed #6868ab4a;
    border-radius: 15px;
    text-align: center;
}


.dragdrop .imageIcon .dragDrop-extension {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: -.03em;
    font-size: 13px;
    width: 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dragdrop .imageIcon .icon:before {
    font-family: dropify;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-left: 0.2em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    content: '\e801';
}
#itemsUploadPrograss .icon:before {
    font-family: dropify;
    font-style: normal;
    font-weight: 100;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 0;
    content: '\e801';
    color: #000;
}
#itemsUploadPrograss .dragDrop-extension {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: -.03em;
    font-size: 11px;
    width: 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
}
#itemsUploadPrograss .imageIcon{
    display: block;
    width: 51px;
    height: 58px;
    font-size: 40px;
    border: 1px dashed #6868ab4a;
    border-radius: 15px;
    text-align: center;
    position: relative;
    float: left;
    margin-right: 7px;
}
.dragdrop:hover .SucsessFile{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: inline-flex !important;
    display: inline-flex;
    border-radius: 11px;
    background: #00000078;
}
.dragdrop .SucsessFile{
    display: none;
}

.dragdrop .SucsessFile{
    width: 100%;
}
.dragdrop .SucsessFile div{
    width: 100%;
}
.dragdrop .SucsessFile .btnSuccessfuly{
    background: green;
    color: #fff;
    padding: 5px 13px;
    border-radius: 5px;
    margin-right: 15px;
    border: 1px solid;
    position: relative;
    z-index: 99999999999;
}

.dragdrop .SucsessFile .btnRemove{
    background: rgb(201, 4, 37);
    color: #fff;
    padding: 5px 13px;
    border-radius: 5px;
    border: 1px solid;
    position: relative;
    z-index: 99999999999;
}
.dragdrop .SucsessFile .btnSuccessfuly:hover{
    box-shadow: 4px 2px 8px #fff;
    color: #fff;
}
.dragdrop .SucsessFile .btnRemove:hover{
    box-shadow: 4px 2px 8px #fff;
    color: #fff;
}

.viewer-in {
    opacity: 1;
    z-index: 9999999999999 !important;
}

.imageStl{
    object-fit: contain;
    width: 147px;
    height: 66px;
    padding: 12px;
}

.boxSTL{
    border: 1px dashed #d9d8d8;
    border-radius: 10px;
}
.countStl .dragdrop .imageIcon{
    border: none !important;
    font-size: 44px !important;
}
.countStl .avatar-lg {
    width: 50px;
    height: 50px;
    margin: 6px !important;
}
.countStl .textProsess{
    position: absolute;
    left: 45%;
    top: 22px;
}
.countStl .divProsess{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d9d7d763;
}

.shoppings .header{
    color: #7e57c2;
    font-size: 15px;
}
.shoppings .card{
    border-radius: 13px;
    min-height: 136px;
    -webkit-box-shadow: 1px 1px 10px #e3e3e3;
    box-shadow: 1px 5px 10px #ebebeb;
}
.shoppings .body{
    min-height: 53px;
    display: inline-flex;
    -webkit-box-align: end;
    align-items: flex-end;
}
.shoppings .btn-dark{
    width: 100px;
    background: #6868ac42;
    border: 1px solid #6868ac26;
    color: #6868AC;
}
.shoppings .btn-dark:focus {
    border: 1px solid #6868ac;
}
.New .card{
    text-align: center;
    border: 1px solid #8bd670;
    cursor: pointer;
}
.New .card:hover{
    box-shadow: 1px 1px 8px #8bd670;
}
.New .card:active{
    box-shadow: none;
}

.LoaderKiven{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: inline-flex;
    align-items: center;
    text-align: center;
    -webkit-box-align: center;
    padding-left: 41%;
    background: #4969914a;
    top: 0;
    left: 0;
}

.btn-outline-primary {
    color: #6868aa;
    border-color: #6868aa;
    margin-right: 3px;
    padding: 8px 27px;
    font-size: 17px;
    cursor: pointer;
}
.btn-outline-primary:focus {
    color: #fff;
}
.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #6868aa;
    border-color: #6868aa;
}

.selectHide{
    -webkit-appearance: none!important;
    width: 70px;
    padding: 6px;
    margin-right: 13px;
    text-align: center;
}
.divGrop{
    display: inline-flex;
    width: 100%;
}
.iti__flag{
    height: 14px;
    background-position: -5065px 0;
}
.iti{
    width: 100%;
    display: inline-flex !important;
}
.iti__flag-container{
    position: relative !important;
}
.iti__selected-flag{
    background-color: #fafafb !important;
    border-radius: 10px;
    border: 1px solid #f1f1f5;
}
.iti__flag-container{
    margin-right: 8px;
}
.iti__arrow{
    display: none;
}
.iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 3px !important;
    margin-right: 3px;
}
.iti input{
    padding-left: 9px !important;
}
.iti__selected-flag .iti__flag{
    display: none !important;
}

.imageUserConfirm{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #ddd8d8;
}

.btn-primary:hover {
    color: #fff;
    background-color: #4b569d;
    border-color: #4b569d;
}
.btn-primary {
    color: #fff;
    background-color: #4b569d;
    border-color: #4b569d;
}
a {
    color: #4b569d;
    text-decoration: none;
    background-color: transparent;
}
.dataTables_scrollBody{
    min-height: 200px;
}


.tabKiven{
    .tabs {
        display: -webkit-inline-box;
        justify-content: center;
        width: 100%;
        overflow-x: auto;
    }

    .tab {
        font-size: 16px;
        padding: 15px 60px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 24px;
    }

    .tab.active {
        background-color: #6868ab;
        color: #fff;
    }

    .content {
        width: 100%;
        margin-top: 25px;
    }

    .content-item {
        display: none;
        padding: 5px;
        border: 1px solid #ccc;
        min-height: 300px;
    }

    .content-item.active {
        display: flex;
        justify-content: center;
        display: inline-block;
        width: 100%;
    }
}

.textNotfMessage{
    text-overflow: ellipsis;
    width: 70%;
    overflow: hidden;
    white-space: nowrap !important;
}

.tabLevel{
    .tabs {
        display: inline-block;
        justify-content: center;
        width: 100%;
        overflow-x: auto;
    }

    .tab {
        font-size: 14px;
        padding: 13px 15px;
        cursor: pointer;
        margin-top: 4px;
        margin-bottom: 9px;
    }

    .tab.active {
        background-color: #6868ab;
        color: #fff;
    }

    .content {
        width: 100%;
        margin-top: 25px;
    }

    .content-item {
        display: none;
        padding: 5px;
        border: 1px solid #ccc;
        min-height: 300px;
    }

    .content-item.active {
        justify-content: center;
        width: 100%;
        display: inline-flex;
        text-align: center;
        align-items: center;
        h1{
            color: #6868aa;
        }
    }
}
.color-red{
    color: red;
}

.jq-toast-wrap, .jq-toast-wrap p {
    font-size: 16px;
    margin-top: 10px;
}

.viewer-footer .viewer-toolbar .viewer-play{
    display: none;
}
.viewer-footer .viewer-toolbar .viewer-next{
    display: none;
}
.viewer-footer .viewer-toolbar .viewer-prev{
    display: none;
}
.viewer-footer .viewer-toolbar .viewer-reset{
    display: none;
}
.viewer-footer .viewer-title{
    display: none;
}

.left-side-menu{
    box-shadow: none;
}

#sidebar-menu>ul>li>a.active {
    color: #7e57c2;
    fill: #835fc5;
}

.NotPeermstion{

h1 {
    font-size: 30vw;
    text-align: center;
    position: fixed;
    width: 81%;
    z-index: 1;
    color: #ffffff26;
    top: 50%;
    transform: translateY(-50%);
}

div {
    background: rgba(0, 0, 0, 0);
    width: 70vw;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 30px 30px 10px;
    box-shadow: 0 0 150px -20px rgba(0, 0, 0, 0.5);
    z-index: 3;
}

P {
    font-family: "Share Tech Mono", monospace;
    color: #f5f5f5;
    margin: 0 0 20px;
    font-size: 17px;
    line-height: 1.2;
}

span {
    color: #f0c674;
}

i {
    color: #8abeb7;
}

div a {
    text-decoration: none;
}

b {
    color: #81a2be;
}

a.avatar {
    position: fixed;
    bottom: 15px;
    right: -100px;
    animation: slide 0.5s 4.5s forwards;
    display: block;
    z-index: 4
}

a.avatar img {
    border-radius: 100%;
    width: 44px;
    border: 2px solid white;
}

@keyframes slide {
    from {
        right: -100px;
        transform: rotate(360deg);
        opacity: 0;
    }
    to {
        right: 15px;
        transform: rotate(0deg);
        opacity: 1;
    }
}

background-image: linear-gradient(120deg,#6868ab 0,#313158 100%);
    height: 78vh;
}

.DoctorActivted{
    background: #00800040;
    color: green;
    padding: 7px 12px;
    border-radius: 6px;
}

.DoctorStopped{
    background: rgba(255, 0, 0, 0.219);
    color: red;
    padding: 7px 12px;
    border-radius: 6px;
}

.imageUserForm{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
}
  
  .check-1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transfrom: translatex(-50%) translatey(-50%);
    width: 44px;
    height: 26px;
    border-radius: 50px;
    position: relative;
  }
  
  .check-1 .inner {
    position: absolute;
    width: 100%;
    height: 24.5px;
    border-radius: 50px;
    border: 2px solid #ff1036;
    background: #ff1036;
    transition: all .2s ease;
  }
  
  .check-1 .bullet {
    position: relative;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    transition: all .3s ease;
    top: 2px;
    left: 4px; 
    box-shadow: 0 3px 3px rgba(0,0,0,.15);
  }
  
  .check-1 input:checked ~ .inner {
    border: 12.5px solid #2ecc71;
    transition: all .2s ease;
  }
  
  .check-1 input:checked ~ .bullet {
    left: 23px;
    background: #fff;
    transition: all .3s ease;
    animation: .2s bullet;
  } 
  
  @keyframes bullet {
    0%, 100% {
      width: 25px;
    }
    40% {
      width: 30px;
    }
  }

  div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: right !important;
}

.chatNameONLogo{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
    position: absolute;
    top: -16px;
    left: 59px;
    font-weight: 600;
    color: #6868ab;
}


.uk-timeline .uk-timeline-item .uk-card {
	max-height: 300px;
}

.uk-timeline .uk-timeline-item {
    display: flex;
    position: relative;
}

.uk-timeline .uk-timeline-item::before {
    background: #dadee4;
    content: "";
    height: 100%;
    left: 19px;
    position: absolute;
    top: 20px;
    width: 2px;
		z-index: -1;
}

.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
    margin-top: 20px;
    width: 40px;
    height: 40px;
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 0 0 1rem;
}
.btnAddStart{
    position: absolute;
    top: -4px;
    left: 11px;
    cursor: pointer;
}
.btnAddEnd{
    position: absolute;
    bottom: 10px;
    left: 11px;
    cursor: pointer;
}

.btnTaskShadow:hover{
    box-shadow: 1px 1px 8px #5f5fad;
    background: #6868ab!important;
}
.LoaderDivTask{
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #ede8e875;
    align-items: center;
    text-align: center;
    -webkit-box-align: center;
    display: inline-flex;
    padding-left: 40%;
}

.btn-outline-info {
    color: #6868ab;
    border-color: #6868ab;
}
.btn-outline-info:hover {
    color: #fff;
    background-color: #6868aa;
    border-color: #6868a9;
}


.TextShadowNUmber{
    color: #6868ab;
    font-size: 17px;
    text-shadow: 1px 3px 5px #b7b0b0;
}